.buynowPdtCnt img {
    width: 242px;
}
.buynowFav {
    width: 42px;
    height: 40px;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0px;
    border-radius: 0px 6px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cards {
    position: relative;
}
.pdtPrice {
    font-family: var(--fontFamily1);
    font-size: 24px;
    font-weight: 700;
    color: var(--primColor);
    margin: 0px;
    padding-bottom: 15px;
}
.buyNowList .listCard {
    height: 225px;
}
.buyNowList .listViewCnt img {
    max-width: 221px;
    width: 100%;
    object-fit: contain;
}
.ListFavicon {
    font-size: 28px;
    color: var(--primColor);
    cursor: pointer;
    margin-bottom: auto;
    margin-left: auto;
    padding: 15px;
}
