.Dashboard .Favourite-Header {
    width: 200px;
    margin: 0 0 25px auto;
    padding-right: 25px;
}
.Dashboard .Favflex {
    /* padding: 0px 8px; */
}

.Dashboard .Favourites-cards {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 10px;
    padding: 15px;
}

.Dashboard .fav-pagination .MuiPagination-root .MuiPagination-ul {
    width: max-content;
    margin: 20px auto;
}
