.FavCard-Banner .favoriteCheck {
    margin-left: 80px;
}

.fawWrap {
    width: 100%;
    max-width: 300px;
    margin-bottom: 25px;
    position: relative;
}

.Favflex {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    position: relative;
    justify-content: space-between;
}

.Favbox-Favflex {
    position: absolute;
    z-index: 998;
    top: 10px;
    right: 10px;
}

@media (max-width: 600px) {
    .Dashboard .Favourites-cards {
        place-content: center;
    }
}
