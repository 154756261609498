.bidSelectVal {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    margin-bottom: 10px;
}

.biddingCnt .bidSelectVal .css-1nmdiq5-menu {
    /* z-index: 999; */
    margin-top: -10px;
    /* text-align: left; */
}

.css-1dimb5e-singleValue {
    /* text-align: left; */
}
