.gridViewCnt {
    width: 100%;
    /* height: 485px; */
    max-width: 289px;
}
.cards {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    /* max-height: 480px;
    height: 100%; */
}
.gridViewCnt .timerCnt {
    padding-top: 10px;
}
.gridViewCnt .lazy-load-image-background {
    height: 235px !important;
}
.gridViewCnt img {
    width: 100%;
    height: inherit !important;
    border-radius: 6px 6px 0px 0px;
    object-fit: contain;
}
.pdtName {
    font-size: 20px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
    padding: 10px;
    margin: 0px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 25px;
    height: 61px;
}
.pdtDescrp {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    max-width: 235px;
    margin: auto;
    /* padding-bottom: 20px; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 45px;
    line-height: 22px;
    margin-bottom: 10px;
    word-break: break-all;
}
.pdtContent {
    text-align: center;
}

.strtTmDiv .tmrVle {
    text-align: center;
    display: block;
    width: 100%;
}

.viewsCountValue {
    position: absolute;
    top: 10px;
    margin: 0;
    left: 10px;
    background: #000000b8;
    color: #fff;
    padding: 5px 25px;
    font-size: 15px;
    border-radius: 100px;
}
.pdtContent .primButton {
    width: 0px;
    /* height: 0px; */
    margin-top: 0px;
    padding-top: 10px;
}
.pdtContent .MuiButtonBase-root.MuiButton-root {
    border-radius: 0px 0px 6px 6px;
}
.gridViewCnt .primButton .MuiButton-label {
    font-family: var(--fontFamily1);
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
}
.gridViewCnt .secButton .MuiButton-label {
    font-family: var(--fontFamily1);
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
}

.gridViewCnt .tmrTxtTop {
    margin-top: 25px;
}

/* ListView */

.listPdtCnt {
    text-align: left;
    padding-left: 20px;
    width: 100%;
}
.listCard {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    margin-bottom: 25px;
}

.listViewCnt .lazy-load-image-background {
    max-width: 245px;
}

.listViewCnt img {
    max-width: inherit;
    width: 100%;
    height: 245px;
    object-fit: contain;
    border-radius: 6px 0px 0px 6px;
}
.pdtCategory {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: #c5c5c5;
    margin: 0px;
    padding-top: 15px;
}
.listViewCnt .timerCnt {
    padding: 10px;
}
.pdtListName {
    font-size: 22px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
    padding-top: 10px;
    padding-bottom: 15px;
    margin: 0px;
    max-height: 83px;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 450px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.pdtStart {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
}
.pdtEnd {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
}
.listPdtDescrp {
    font-size: 16px;
    font-family: var(--fontFamily1);
    padding-right: 24px;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 450px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.timeBtn {
    width: 373px;
}
.timeBtn .MuiButtonBase-root.MuiButton-root {
    border-radius: 0px 0px 6px 0px;
}
.listViewCnt .primButton .MuiButton-label {
    font-family: var(--fontFamily1);
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
}
.timeTitle {
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
    margin: 0px;
}

.timerBasic h4 {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
    margin: 5px 0 0;
}

.timerBasic h4:not(:first-child) {
    padding-left: 4px;
}

.lotCardWrpr {
    position: relative;
}

.lotCardWrpr .favBox {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #00000028;
    border-radius: 100px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.lotCardWrpr .currPrice {
    font-weight: 700;
}

/*home auction card */
.homeGridCard {
    display: flex;
    align-items: stretch;
    /* width: 100%; */
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 3px 10px #00000026;
    width: 800px;
}

.homeGridCard .crdImage {
    max-width: 192px;
    width: 100%;
    height: 208px;
}

.homeGridCard .crdImage img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.homeGridCard .tmrVle {
    font-weight: 500;
    margin-bottom: 0;
}

.homeGridCard .pdtName {
    padding: 0;
    -webkit-line-clamp: 1;
    height: 29px;
}

.homeGridCard .auctionInfoWrpr {
    width: 100%;
    padding-left: 15px;
}

.homeGridCard .pdtContent {
    text-align: left;
    width: 100%;
}

.homeGridCard .pdtDescrp {
    max-width: unset;
    width: 100%;
    margin-top: 73px;
}

.homeGridCard .tmrActnCntnr {
    max-width: 250px;
    width: 100%;
    padding-left: 20px;
    border-left: 1px solid #e4e4e4;
}

.homeGridCard .tmrActnCntnr .primButton button {
    background-color: var(--secColor);
    border-radius: 4px;
    border: none;
}

.homeGridCard .tmrActnCntnr .primButton button .MuiButton-label {
    font-size: 14px;
}

.homeGridCard .tmrActnCntnr .material-icons-outlined {
    font-size: 50px;
    color: #2e2e2e;
}

.homeGridCard .tmrActnCntnr .timeTitle {
    margin-bottom: 10px;
    color: #2e2e2e;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: white;
    z-index: 0;
}

.homeGridCard .tmrActnCntnr .timeTitle::after {
    content: '';
    height: 1px;
    width: 15%;
    position: absolute;
    top: 9px;
    left: -20%;
    background: #e4e4e4;
}

.homeGridCard .tmrActnCntnr .timeTitle::before {
    content: '';
    height: 1px;
    width: 15%;
    position: absolute;
    top: 9px;
    right: -20%;
    background: #e4e4e4;
}

.homeGridCard .tmrActnCntnr .emptyTimerDiv {
    height: 76px;
    color: var(--primColor);
    font-weight: 500;
}

.homeGridCard .tmrActnCntnr .timerCnt h4 {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 500;
    height: 28px;
}

.homeGridCard .tmrActnCntnr .timerCnt div p {
    text-transform: capitalize;
    color: #2e2e2e;
}

.homeGridCard.badge.timed {
    background: #e3bb2a;
    color: #333;
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.homeGridCard.badge.live {
    background: #e33f3f;
    color: #fff;
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.grid-badge {
    position: absolute;
    left: 10px;
    top: 10px;
    background: var(--primColor) !important;
    padding: 8px;
    min-width: 75px;
    color: #fff;
}

.wngSts {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    bottom: 0px;
    left: 0;
    width: 100%;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}

.wngSts.grnTxt {
    color: rgb(2, 102, 2);
    background: rgb(185 253 199);
}

.wngSts.LsgTxt {
    color: rgb(188, 7, 7);
    background: rgb(255, 229, 229);
}

/* Responsiveness */

@media (max-width: 850px) {
    .homeGridCard {
        width: 100%;
        flex-wrap: wrap;
    }

    .homeGridCard .crdImage {
        max-width: 100%;
    }

    .homeGridCard .pdtContent {
        margin-top: 14px;
        text-align: center;
    }

    .homeGridCard .strtTmDiv {
        justify-content: center !important;
    }

    .homeGridCard .tmrActnCntnr {
        margin-top: 10px;
        /* max-width: 100%;
        padding-left: 0;
        border-left: 0; */
    }
}

@media (max-width: 630px) {
    .homeGridCard .auctionInfoWrpr {
        flex-wrap: wrap;
    }

    .homeGridCard .tmrActnCntnr {
        max-width: 100%;
        padding-left: 0;
        border-left: 0;
    }

    .homeGridCard .pdtDescrp {
        margin-top: 25px;
    }

    .homeCnt .homeGridCard .primButton {
        max-width: unset;
    }
}

@media (max-width: 425px) and (min-width: 320px) {
    .gridViewCnt img {
        height: 110px;
    }
    .pdtName {
        font-size: 15px;
        height: auto;
        max-height: 65px;
    }
    .pdtDescrp {
        height: auto;
        margin-bottom: 5px;
        font-size: 13px;
        max-height: 49px;
    }
    .timerCnt h4 {
        font-size: 14px;
    }

    .Mins p,
    .Mins p,
    .Hrs p,
    .Days p {
        font-size: 11px;
    }
}
