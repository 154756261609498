.Dashboard .custom-Components .Dashboard-Tabpanel .MuiTabs-root {
    margin-left: 25px;
}

.Dashboard .Dashboard-Tabpanel .MuiButtonBase-root {
    font-weight: 500;
    width: 106px;
    height: 40px;
}
.Dashboard .Dashboard-Tabpanel .MuiButton-label {
    font-weight: 600;
    font-family: var(--fontFamily1);
    text-transform: none;
}

.Dashboard .Dashboard-Tabpanel .MuiButtonBase-root.Mui-selected.MuiTab-wrapper {
    color: red;
}
.Dashboard .Mui-selected .MuiTab-wrapper {
    color: var(--primColor);
}
.Dashboard .MuiTab-wrapper {
    font-family: var(--fontFamily2);
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
}
.Dashboard .MuiTabs-indicator {
    background: linear-gradient(to right, #ff6161 0%, #c82727 100%) !important;
}

@media (max-width: 600px) {
    .Dashboard .MuiTabs-indicator {
        bottom: 8px;
    }
}
