.image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 500px;
}
.timerBid {
    padding-bottom: 35px;
}
.placeBid .Mui-disabled {
    border: none;
}

/* .placeBid .customInput .MuiInput-root {
    width: 100%;
    max-width: 200px;
} */

.imgDrawer .image-gallery-thumbnail-inner {
    height: 80px;
}

.imgDrawer .image-gallery-thumbnail-inner img {
    object-fit: contain;
    height: inherit;
}

.imgDrawer .image-gallery-thumbnail {
    border: 2px solid transparent;
}

.imgDrawer .image-gallery-thumbnail.active,
.imgDrawer .image-gallery-thumbnail:hover,
.imgDrawer .image-gallery-thumbnail:focus {
    border: 2px solid #2d2d2d;
}

.lotId {
    display: block;
    text-align: right;
    margin: 0;
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: 700;
}

.imgDrawer .MuiPaper-root.MuiDrawer-paper > div {
    padding: 30px;
}

@media (max-width: 1000px) {
    .placeBid .MuiFormControl-root.MuiTextField-root {
        width: 100%;
    }

    .placeBid .MuiButtonBase-root.MuiButton-root,
    .tableCnt th:nth-last-child(1) {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .placeBid.sliderPlaceBid {
        flex-wrap: wrap;
    }

    .placeBid.sliderPlaceBid input::-webkit-input-placeholder {
        white-space: pre-line;
        position: relative;
        top: 0;
    }

    .placeBid.sliderPlaceBid .MuiButtonBase-root.MuiButton-root {
        height: 35px;
    }

    .placeBid.sliderPlaceBid .MuiButtonBase-root.MuiButton-root,
    .tableCnt th:nth-last-child(1) {
        width: 100%;
    }
    .placeBid.sliderPlaceBid .MuiButtonBase-root.MuiButton-root {
        width: 100%;
    }

    .placeBid .MuiFormControl-root.MuiTextField-root {
        width: 100%;
    }
}
