.Dashboard .custom-Components .Lost-Auction-Tabs {
    display: flex;
    margin-left: 25px;
}

.Dashboard .custom-Components .Lost-Auction-Tabs a:nth-of-type(3) {
    color: red;
    text-transform: none;
    border-bottom: 2px solid red;
}

.Dashboard .custom-Components .Lost-Auction-Tabs {
    margin-bottom: 30px;
}

.Dashboard .custom-Components .Lost-Auction-Tabs a {
    margin-right: 50px;
    font-weight: 600;
    color: #a8a7a7;
}

.Lost-Auction-Tabs .MuiButtonBase-root {
    height: 26px;
    padding-top: 5px;
}

.LostAuction-Table .MuiButtonBase-root {
    height: 26px;
    padding-top: 5px;
}

.Dashboard .lost-pgn {
    width: max-content;
    margin: 20px auto;
}
