.Dashboard .Notification-Panel .table {
    text-align: left;
}

.Dashboard .Notification-Panel .table tr th:last-child {
    text-align: center;
}

.Dashboard .Notification-Panel .table tr td:last-child .MuiFormGroup-root.MuiFormGroup-row {
    justify-content: flex-end;
}

.Dashboard .Notification-Panel td {
    vertical-align: middle;
}

.Dashboard .Notification-Panel th {
    vertical-align: middle;
}

.Dashboard .Notification-Panel .MuiButtonBase-root {
    width: 116px;
    height: 42px;
}
.Dashboard .MuiButton-label {
    text-transform: none;
    font-family: var(--fontFamily1);
}

.Dashboard .Notification-Panel .MuiSvgIcon-root {
    color: red;
}

/* responsive css */

@media (max-width: 700px) {
    .Dashboard .Notification-Panel .MuiButtonBase-root {
        width: auto;
        height: auto;
    }
    .Dashboard .Notification-Panel .table tr th:last-child {
        text-align: left;
    }
}
