.privateChat .chatContainer {
    width: 964px;
    height: 605px;
    background-color: #f0eefb;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
}
.privateChat .chatContainer .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0px 0px 0px 10px;
    width: 837px;
    background-color: white;
}
.privateChat .chatContent {
    overflow: auto;
    height: 91%;
    padding: 21px;
}
.privateChat .Dashboard .custom-Components {
    box-shadow: none;
    padding-top: unset;
}
.privateChat .publicAccount {
    display: none !important;
}

/* responsive css */

@media (max-width: 1350px) {
    .privateChat .chatContainer {
        width: 100%;
    }
    .privateChat .chatContainer .MuiOutlinedInput-root {
        width: 100%;
    }
}
@media (max-width: 991px) {
    .privateChat .Dashboard .custom-Components {
        margin: 0 15px;
    }
    .custom-container-Dashboard {
        padding-bottom: 20px;
    }
    .Dashboard .Full-Container .pp-edit {
        right: 0;
        left: 0;
    }
}
