.Dashboard .table td:nth-last-child(1) button {
    font-weight: 600;
    width: 150px;
    height: 40px;
    font-family: var(--fontFamily1);
    text-transform: none;
}

.Dashboard .Paymentab-Dashboard-Tabpanel .MuiButtonBase-root.Mui-selected.MuiTab-wrapper {
    color: red;
}

.Dashboard .custom-Components .Paymentab-Dashboard-Tabpanel .MuiTabs-root {
    margin-left: 25px;
}
