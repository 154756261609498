.cardHolder {
    box-shadow: 0px 3px 6px #00000029;
    max-width: 650px;
    width: 100%;
    border-radius: 4px;
}
.cardHolder img {
    width: 198px;
    height: 198px;
    object-fit: cover;
}
.cardHolder .checkoutCntWrp {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
}
.cardHolder .checkoutPdtTitle,
.cardHolder .priceTitle {
    font-size: 14px;
    font-family: var(--fontFamily1);
    color: #5d646c;
    font-weight: 500;
    margin: 0;
    margin-bottom: 3px;
}
.cardHolder .checkoutCntWrp {
    padding-left: 15px;
}
.cardHolder .checkoutTitle {
    padding-bottom: 25px;
}
.cardHolder .checkoutPdtName,
.cardHolder .checkoutPdtPrice {
    font-size: 17px;
    font-family: var(--fontFamily1);
    color: var(--secColor);
    font-weight: 600;
    margin: 0;
}
