* {
    box-sizing: border-box;
}

.Signup {
    display: flex;
    justify-content: center;
    text-align: center;
}

.coinImg {
    width: 100%;
    overflow: hidden;
}

.custom-container-signup {
    padding-left: 60px !important;
    padding-right: 60px !important;
    width: 100% !important;
}

.Signup .Registration-Banner {
    background-color: white;
}

.Signup .signup-Text h1 {
    height: 35px;
    color: #002c6e;
    font-size: 26px;
    font-weight: 600;
}

.Signup .signup-inputfields {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0 15px 0;
}

/* .signup-inputfields > div {
    flex: 0 50%;
} */

.Signup .Registration-Banner {
    padding-top: 25px;
}

.Signup .logo-signup {
    margin-bottom: 25px;
}

.Signup .registration-inp {
    margin: 0 0 20px 0;
}

.Signup .signup-btn .primButton .MuiButton-root {
    width: 191px;
    text-transform: none;
}

.Signup .intl-tel-input input {
    width: 100%;
}

.Signup .intl-tel-input {
    width: 100%;
}

/* .registration-inp .customSelect .MuiFormControl-root {
    width: 280px;
}

.registration-inp .customInput .MuiFormControl-root {
    width: 280px;
} */

.Signup .signin-Instead {
    margin-top: 25px;
}

.Signup .signin-Instead a {
    border-bottom: 1px solid #002c6e;
    text-decoration: none;
    color: #002c6e;
    font-weight: 600;
}

.Signup .errorMessage-1 {
    color: red;
    margin-top: 20px;
    text-align: left;
}

.Signup .errorMessage-2 {
    color: red;
    margin-top: 20px;
    text-align: left;
}

@media (max-width: 992px) {
    .Signup {
        display: grid;
        grid-template-columns: 50% 1fr;
    }
    .custom-container-signup {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}
@media (max-width: 860px) {
    .Signup {
        display: grid;
        grid-template-columns: 30% 1fr;
    }
}
@media (max-width: 630px) {
    .Signup {
        grid-template-columns: 1fr;
    }
    .Login {
        display: grid;
        text-align: center;
        justify-content: normal;
    }
    .Signup .Registration-Banner {
        padding-bottom: 25px;
        padding-top: 100px;
    }
    .coinImg img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .coinImg {
        position: relative;
        display: block;
        height: 100px;
        width: 100%;
        overflow: hidden;
    }
    .coinImg:after {
        content: '';
        position: absolute;
        background: #0000002e;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 5;
        height: 100%;
    }
    .Signup .logo-signup {
        width: 230px;
        height: 65px;
        z-index: 9999;
        display: block;
        text-align: center;
        position: absolute;
        margin: auto;
        top: 150px;
        left: 0;
        right: 0;
        background: white;
        border-radius: 10px;
        padding: 10px;
    }
    .Signup .logo-signup img {
        width: 100%;
    }
}
@media (max-width: 425px) {
    .Signup .registration-inp {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .Signup .signup-inputfields {
        display: grid;
    }
    .cmlftogo {
        width: 86px;
    }
}
