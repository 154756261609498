.dropDown.MuiFormControl-root.makeStyles-formControl-18 {
    margin: 0px;
    min-width: unset;
}
.dropDown .MuiInputBase-root.MuiInput-root {
    margin: 0px;
}
.dropDown .MuiInput-underline:before {
    /* content: unset; */
}
.dropDown .MuiInput-underline:after {
    /* content: unset; */
}
