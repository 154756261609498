.lvPrcWrpr {
    background: var(--secColor);
    padding: 8px 10px;
}

.lvPrcWrpr p {
    margin: 0 10px;
    font-size: 14px;
    color: white;
}

.lvPrcWrpr p.lvTtle {
    font-weight: 900;
    color: #cdab63;
}

.lvPrcWrpr p.vlvs {
    font-weight: 800;
}

.lvPrcWrpr p span {
    margin-left: 4px;
    font-weight: normal;
}

@media (max-width: 650px) {
    .lvPrcWrpr {
        flex-wrap: wrap;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .lvPrcWrpr .lvTtle {
        width: 100%;
        margin-bottom: 5px;
    }

    .lvPrcWrpr p {
        font-size: 13px;
    }
}
