.App {
    text-align: center;
    width: 100% !important;
    /* max-width: 1600px; */
    margin-left: auto !important;
    margin-right: auto !important;
    background: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0px;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 20em) /* 320px */ and (max-device-width: 30em) /* 480px */ {
    /* STYLES GO HERE */
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 20.063em) /* 321px */ {
    /* STYLES GO HERE */
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 20em) /* 320px */ {
    /* STYLES GO HERE */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 48em) /* 768px */ and (max-device-width: 64em) /* 1024px */ {
    /* STYLES GO HERE */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 48em) /* 768px */ and (max-device-width: 64em) /*1024px */ and (orientation: landscape) {
    /* STYLES GO HERE */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 48em) /* 768pxpx */ and (max-device-width: 64em) /* 1024px */ and (orientation: portrait) {
    /* STYLES GO HERE */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 76.5em) /* 1224px */ {
    /* STYLES GO HERE */
}

/* Large screens ----------- */
@media only screen and (min-width: 114em) /* 1824px */ {
    /* STYLES GO HERE */
}

/* iPhone 5 (portrait &amp; landscape)----------- */
@media only screen and (min-device-width: 20em) /* 320px */ and (max-device-width: 35.5em) /* 568px */ {
    /* STYLES GO HERE */
}

/* iPhone 5 (landscape)----------- */
@media only screen and (min-device-width: 20em) /* 320px */ and (max-device-width: 35.5em) /* 568px */ and (orientation: landscape) {
    /* STYLES GO HERE */
}

/* iPhone 5 (portrait)----------- */
@media only screen and (min-device-width: 20em) /*320px*/ and (max-device-width: 35.5em) /* 568px */ and (orientation: portrait) {
    /* STYLES GO HERE */
}

@media only screen and (max-device-width: 991px) /* 1072px */ {
    /* STYLES GO HERE */
}
