.Banner-Forgotpassword {
    display: flex;
    justify-content: center;
    text-align: center;
}

.Banner-Forgotpassword .forgotpassImg {
    width: 100%;
}

.custom-container-forgotPassword {
    padding-top: 140px;
    padding-left: 140px !important;
    padding-right: 140px !important;
    width: 100% !important;
}

.forgotpassImg img {
    height: 700px;
    object-fit: cover;
    width: 100%;
}

.forgotpassDetails h3 {
    color: #002c6e;
    margin-top: 60px;
    margin-bottom: 50px;
    font-weight: 600;
}

.forgotpassDetails .customInput {
    margin-bottom: 50px;
}

.forgotpassDetails .primButton {
    margin-bottom: 40px;
}

.forgotpassDetails .MuiButtonBase-root {
    width: 190px;
    text-transform: none;
}

.forgotpassDetails a {
    text-decoration: none;
    border-bottom: 1px solid #002c6e;
    color: #002c6e;
}

.forgotpassDetail .forgPassInp .MuiInputBase-input {
    padding-bottom: 15px !important;
}

.forgotPassError {
    color: red;
    text-align: left;
    transform: translateY(-30px);
}

.forgotpassDetails img {
    width: 300px;
}

@media (max-width: 767px) {
    .forgotpassImg {
        display: none;
    }
    .custom-container-forgotPassword {
        padding: 60px 30px !important;
    }
    .forgotpassDetails img {
        width: 200px;
    }
}
