.carCardWrpr {
    padding: 15px;
    border-bottom: 1px solid rgb(219, 219, 219);
}

.carCardWrpr .imageContainer {
    max-width: 110px;
    width: 100%;
    height: 110px;
    margin-right: 25px;
}

.carCardWrpr .imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.carCardWrpr .rvwCnt {
    text-align: left;
}

.carCardWrpr .rvwCnt h5 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
}

.carCardWrpr .rvwCnt p {
    color: gray;
    margin-bottom: 0;
    min-height: 7ex;
}

.carCardWrpr .rvwCnt .rvwrDtl h6 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: capitalize;
}

.carCardWrpr .rvwCnt .rvwrDtl .rtngCntnr .material-icons {
    font-size: 16px;
    color: gray;
}
.carCardWrpr .rvwCnt .rvwrDtl .rtngCntnr .material-icons.active {
    color: var(--primColor);
}

.carCardWrpr .rvwCnt .verifiedIcon {
    font-size: 17px;
    color: green;
}

.carCardWrpr .imageContainer img.plcHldImg {
    background: whitesmoke;
    object-fit: scale-down;
}

@media (max-width: 600px) {
    .carCardWrpr .rvwCnt h5 {
        white-space: pre-wrap;
    }
}

@media (max-width: 425px) {
    .carCardWrpr .rvwCnt p {
        font-size: 13px;
    }
    .carCardWrpr .rvwCnt h5 {
        font-size: 15px;
    }
}
