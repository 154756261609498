.Days {
    text-align: center;
}
.Hrs {
    text-align: center;
}
.Mins {
    text-align: center;
}
.Sec {
    text-align: center;
}
.timerCnt h4 {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
    margin: 0px;
}
.Days p {
    font-size: 12px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    color: var(--secColor);
    margin: 0px;
}
.Hrs p {
    font-size: 12px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    color: var(--secColor);
    margin: 0px;
}
.Mins p {
    font-size: 12px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    color: var(--secColor);
    margin: 0px;
}
.Sec p {
    font-size: 12px;
    font-family: var(--fontFamily1);
    font-weight: 500;
    color: var(--secColor);
    margin: 0px;
}
.dot {
    font-size: 19px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    padding-right: 10px;
    padding-left: 10px;
    margin: 0px;
}
.closed {
    font-family: var(--fontFamily1);
    font-size: 20px;
    color: var(--primColor);
    font-weight: 600;
    margin: 0px;
    padding-bottom: 7px;
}
.timerCnt.closedTime {
    height: 44px;
    align-items: center;
}
.timerCnt {
    padding-top: 10px;
}

.tmrTxtTop {
    color: var(--primColor);
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}
