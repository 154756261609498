.cardSelect {
    background: #ebebeb;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 5px;
}

.additionalPay .secButton {
    margin-top: 10px;
}

.additionalPay .secButton button {
    width: 100% !important;
}

@media (max-width: 600px) {
    .additionalPay .tit {
        margin-top: 15px;
    }
}
