.Banner-carddetails {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: white;
}

.custom-container {
    padding-top: 110px !important;
    padding-left: 140px !important;
    padding-right: 140px !important;
    width: 100% !important;
}

.Banner-carddetails .bannerImg img {
    height: 700px;
}

.cardInput {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-basis: 33.333333%;
    margin-bottom: 30px;
}

/* .cardInput .customInput:nth-of-type(1) {
    width: 100%;
}

.cardInput .customInput:nth-of-type(2) {
    width: 100%;
}

.cardInput .customInput:nth-of-type(3) {
    width: 70px;
}

.cardInput .customInput:nth-of-type(4) {
    width: 270px;
} */

.cardDetails-Data .cardDetails-Logo {
    margin-bottom: 40px;
}

.cardDetails-Data h3 {
    color: #002c6e;
    font-weight: 600;
    margin-bottom: 30px;
}

.cardInput .customInput .MuiFormControl-root {
    margin-bottom: 30px;
}

.cardDetails-Data .MuiButtonBase-root {
    width: 190px;
    margin-bottom: 30px;
}

.cardDetails-Data a {
    text-decoration: none;
    color: #002c6e;
    border-bottom: 1px solid #002c6e;
    font-weight: 600;
}

.creditcardnoError {
    color: red;
    text-align: left;
}

.Banner-carddetails .MuiFormHelperText-root {
    color: red;
}

.cardDetails-Logo img {
    width: 300px;
}
