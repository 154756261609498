.loaderWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000042;
    z-index: 99999;
    backdrop-filter: blur(30px);
    top: 0;
    left: 0;
}
