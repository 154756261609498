.paymentDetails .paymentTotal,
.paymentDetails .paymentCard {
    border: 2px dashed #c9c9c9;
    padding: 23px 25px;
    width: 550px;
    border-radius: 6px;
}

.paymentDetails .totalTitle,
.paymentDetails .convenienceTitle,
.paymentDetails .taxTitle,
.paymentDetails .grandTitle {
    font-size: 18px;
    font-family: var(--fontFamily1);
    color: var(--secColor);
    font-weight: 600;
    margin: 0;
}

.paymentDetails .totalTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.paymentDetails .totalPrice,
.paymentDetails .convenienceFee,
.paymentDetails .taxPrice,
.paymentDetails .grandPrice {
    font-size: 18px;
    font-family: var(--fontFamily1);
    font-family: var(--secColor);
    font-weight: 700;
    margin: 5px;
}

.paymentDetails .paymentCard {
    padding: 20px;
    margin-top: 25px;
}

.paymentDetails .cardText .customInput {
    padding: 15px 0px;
}

.paymentDetails .primButton .MuiButtonBase-root {
    width: 200px;
}

.paymentDetails .primButton .MuiButton-label {
    text-transform: none;
}

.paymentDetails .paymentCard .errorTxt {
    color: red;
    text-align: left;
    color: red;
    font-size: 12px;
    font-weight: 500;
}

.paymentCard_static .errorTxt {
    color: red;
    text-align: left;
    color: red;
    font-size: 12px;
    font-weight: 500;
}
