.mobileLstCard {
    margin-bottom: 20px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 4px;
    padding: 11px 10px;
}

.mobileLstCard .imgCntnr {
    min-width: 130px;
    max-width: 200px;
    height: 150px;
    margin-right: 10px;
}

.mobileLstCard .imgCntnr img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: whitesmoke;
}

.mobileLstCard .rtSdInfo .pdtNm {
    font-size: 17px;
    font-weight: 600;
}

.mobileLstCard .rtSdInfo .pdtDescrp {
    margin-left: 0;
    max-width: unset;
    height: 66px;
    -webkit-line-clamp: 3;
    margin-bottom: 0;
}

.mobileLstCard .currPrice span {
    font-weight: 700;
    color: var(--secColor);
}

.mobileLstCard .placeBid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: unset;
}

.mobileLstCard .placeBid .primButton {
    margin-top: 0;
    margin-left: 10px;
    max-width: 240px;
}

.mobileLstCard .placeBid .primButton button {
    height: 56px;
}

@media (max-width: 400px) {
    .mobileLstCard .imgNmCntnr {
        flex-wrap: wrap;
    }

    .mobileLstCard .imgCntnr {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        max-width: unset;
    }
}
