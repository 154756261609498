header {
    height: auto;
}

header .customContainer {
    padding: 25px 0px;
}

/* header .logo {
    width: 250px;
    height: 100%;
    margin: 0px;
} */
header .siteLogo {
    height: 80px;
}

header .Search {
    width: 375px;
    height: 48px;
    border: 1px solid var(--secColor);
    border-radius: 25px;
    margin-left: 20px;
    margin-right: auto;
}

header .Search .searchInputGroup {
    display: grid;
    width: 50%;
    grid-template-columns: 1fr auto;
}

header .Search .searchInputGroup:nth-child(1) input {
    border-right: 1px solid #dcdcdc;
}

header .Search .customInput,
.Search.srchSidBar .customInput {
    width: 100%;
}

header .Search .customInput .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
header .Search.srchSidBar .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
    width: 100%;
}

header .Search .customInput .MuiInputBase-input .MuiOutlinedInput-input,
header .Search.srchSidBar .MuiInputBase-input .MuiOutlinedInput-input {
    height: 48px;
}

header .Search .customInput .MuiOutlinedInput-input,
header .Search.srchSidBar .MuiOutlinedInput-input {
    padding: 13.5px 14px;
}

header .Search.srchSidBar .MuiOutlinedInput-input,
.beforHead.tpSliderWrpr .Search .MuiOutlinedInput-input {
    padding-right: 27px;
}

header .Search .MuiButtonBase-root.MuiButton-root,
header .Search.srchSidBar .MuiButtonBase-root.MuiButton-root {
    width: 46px;
    height: 46px;
    background-color: var(--secColor);
    border-radius: 25px;
    min-width: 0px;
    color: white;
}

header .headerLinks {
    align-items: center;
    margin: 0 0 0 20px;
}

header .headerLinks li {
    padding-right: 25px;
    text-decoration: none;
    list-style-type: none;
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
    white-space: pre;
    color: var(--secColor);
    font-family: var(--fontFamily1);
}

header .headerLinks li.prlDrop {
    padding-right: 0;
}

header .headerLinks a {
    color: black;
    display: block;
}

header .headerLinks a:hover,
header .headerLinks a.active,
header .headerLinks a.active li,
header .headerLinks a:hover li {
    text-decoration: none;
    color: var(--primColor) !important;
    position: relative;
}

header .headerLinks a.active li::after {
    content: '';
    width: calc(100% - 35px);
    height: 2px;
    background: var(--primColor);
    position: absolute;
    left: 0;
    bottom: -10px;
}

header .headerLinks a.active {
    font-weight: bold;
}

header .primButton .MuiButtonBase-root.MuiButton-root {
    width: 200px;
    height: 50px;
    /* margin-right: 35px; */
}

header .secButton .MuiButtonBase-root.MuiButton-root {
    width: 141px;
    height: 50px;
}

header .primButton .MuiButton-label {
    font-family: var(--fontFamily1);
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
}

header .secButton .MuiButton-label {
    font-family: var(--fontFamily1);
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
}

/* .quickNots span:nth-child(n) {
    padding-left: 15px;
    font-size: 25px;
} */
.quickNots .headerNav .MuiButton-root {
    min-width: 45px;
}

.quickNots {
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    align-items: center;
}

header .prflButton .MuiButton-label {
    font-family: var(--fontFamily1);
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    color: var(--secColor);
}

header .prflButton .MuiButton-label .material-icons {
    margin-right: 10px;
}

header .MuiBadge-root .material-icons,
header .MuiBadge-root .material-icons-outlined {
    color: var(--secColor);
}

/* responsive */

@media (max-width: 1366px) {
    header .Search .customInput .MuiOutlinedInput-input,
    header .Search.srchSidBar .MuiOutlinedInput-input {
        font-size: 14px;
    }
}

@media (max-width: 1200px) {
    header .secButton .MuiButtonBase-root.MuiButton-root {
        width: 100px;
        height: 40px;
    }

    header .primButton .MuiButtonBase-root.MuiButton-root {
        width: auto;
        height: 40px;
    }

    header .secButton .MuiButton-label {
        font-size: 14px;
    }

    header .primButton .MuiButton-label {
        font-size: 14px;
    }

    header .Search {
        width: 280px;
        margin: unset;
    }

    header .logo {
        width: 200px;
    }
}

@media (max-width: 1050px) {
    header .logo {
        width: 145px;
        height: auto;
    }
}

@media (max-width: 991px) {
    .MuiList-padding {
        padding: 0;
    }

    header.beforHead {
        padding: 0;
    }

    .beforHead ul .MuiListItem-gutters.active {
        border-left: 4px solid #d40000;
    }

    .beforHead ul .MuiListItem-gutters {
        padding: 12px 10px;
    }

    .beforHead ul i.fas.fa-gavel {
        font-size: 20px;
        color: #757575;
        padding-right: 15px;
    }

    .beforHead ul .material-icons {
        padding-right: 10px;
        color: rgb(117 117 117);
    }

    header .headerLinks a {
        color: #797979;
    }

    .navLogo a img {
        max-width: 150px;
    }

    .navLogo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        border-bottom: 1px solid #e2e2e2;
    }

    header .headerLinks a {
        border-bottom: 1px solid #e2e2e2;
    }

    header {
        padding: 10px 0px;
        height: auto;
        position: sticky;
        top: 0;
        z-index: 999;
        box-shadow: 0px 0px 8px 1px #e8e8e8;
    }

    .resHeader .hambuger {
        font-size: 34px;
        padding-right: 0;
    }

    header .Search {
        width: auto;
        margin: 15px;
        border: 0;
        height: auto;
    }

    header .headerLinks {
        padding-left: 0;
    }

    header .headerLinks li {
        padding: 10px 25px;
        display: grid;
        grid-template-columns: 15% 1fr;
        align-items: center;
    }

    header .primButton .MuiButtonBase-root.MuiButton-root {
        width: 250px;
    }

    header .secButton .MuiButtonBase-root.MuiButton-root {
        width: 250px;
    }

    header .headerLinks li .MuiButton-text {
        justify-content: end;
        color: rgb(121 121 121);
        padding: 0;
    }
}

@media (max-width: 600px) {
    header .logo {
        width: 100px;
    }
}

.auctionHasItems {
    padding: 15px 30px;
    background: #eaf4a8;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
}

.auctionHasItems h3 {
    font-size: 18px;
    font-weight: 500;
    color: #4f5815;
    margin-left: 30px;
}

.auctionHasItems img {
    width: 30px;
    height: auto;
    object-fit: contain;
}

.auctionHasItems h3:last-child {
    margin-bottom: 0;
}

.auctionHasItems h3 a {
    color: inherit;
    font-weight: 600;
}
