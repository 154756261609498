.cardBackground {
    position: relative;
    width: 300px;
}

.cardsData {
    position: relative;
    margin-bottom: 10px;
}

.cardcontentwrap {
    position: absolute;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transform: translateY(-75px);
    margin: 0 10px 0 10px;
}

.cardcontentwrap p {
    font-size: 12px;
}

.cardcontentwrap p:nth-of-type(3) {
    font-weight: 300;
}

.parentCard .MuiButtonBase-root {
    border-radius: 6px;
    text-transform: none;
}

.parentCard {
    margin-bottom: 10px;
    margin-right: 14px;
}
.deleteCard {
    padding: 0px;
}
