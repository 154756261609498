.dropDown.MuiFormControl-root.makeStyles-formControl-18 {
    margin: 0px;
    min-width: unset;
}
.dropDown .MuiInputBase-root.MuiInput-root {
    margin: 0px;
}
.dropDown .MuiInput-underline:before {
    /* content: unset; */
}
.dropDown .MuiSelect-select.MuiSelect-select {
    line-height: 38px;
}

@media (max-width: 475px) {
    .resultPrePage .dropDown .MuiSelect-select.MuiSelect-select {
        line-height: 20px;
    }
}
