.Dashboard .custom-Components .Won-Auction-Tabs {
    display: flex;
    margin-left: 25px;
}

.Dashboard .custom-Components .Won-Auction-Tabs a:nth-of-type(2) {
    color: red;
    text-transform: none;
    border-bottom: 2px solid red;
}

.Dashboard .custom-Components .Won-Auction-Tabs {
    margin-bottom: 30px;
}

.Dashboard .custom-Components .Won-Auction-Tabs a {
    margin-right: 50px;
    font-weight: 600;
    color: #a8a7a7;
}

.Dashboard .wonAuction-Table .MuiButtonBase-root {
    height: 26px;
    padding-top: 5px;
    width: 102px;
}

.Dashboard .wonAuction-Table .Paid {
    color: #008b25;
}

.Dashboard .wonAuction-Table .Unpaid {
    color: #e18700;
}

.wonAuction-Table .primButton .MuiButtonBase-root {
    height: 26px;
    width: 102px;
    padding-top: 7px;
}

.Dashboard .won-pgn {
    width: max-content;
    margin: 20px auto;
}
.Dashboard .wonAuction-Table .das-togle-switch .MuiButtonBase-root {
    justify-content: normal;
}

.Dashboard .tableSwitch {
    display: flex;
    align-items: center;
    margin-bottom: -7px;
}

.Dashboard .tableSwitch .MuiSwitch-root {
    height: 35px;
}

.Dashboard .tableSwitch .MuiSwitch-thumb {
    width: 18px;
    height: 18px;
}

.Dashboard .tableSwitch .material-icons {
    margin-left: 5px;
    color: #8d8d8d;
    cursor: help;
}

@media (max-width: 700px) {
    .Dashboard .tableSwitch {
        flex-wrap: nowrap;
        flex-direction: row-reverse;
    }
    .Dashboard .tableSwitch .das-togle-switch {
        width: 100%;
    }
}
