.checkoutContainer {
    padding: 25px 60px;
    background-color: white;
    margin-top: 20px;
}

.checkoutPdt {
    width: 100%;
}

.checkoutContainer .checkoutTitle {
    font-size: 22px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    text-align: left;
}

.checkoutContainer .paymentTitle {
    font-size: 22px;
    font-family: var(--fontFamily1);
    font-weight: 600;
}

.checkoutPdt .cardHolder {
    margin-bottom: 30px;
}

.checkoutPdt .adBnkBtns {
    width: 100%;
}

.adBankWrpr.cardContainer {
    padding-left: 0;
    margin-bottom: 25px;
}

.card-1-Container.bankPaymentWrpr,
.card-1-Container.bankPaymentWrpr button {
    width: 100%;
    border-radius: 20px;
    padding: 0;
    margin-bottom: 10px;
}

.card-1-Container.bankPaymentWrpr button h6 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.adBankPopup .modal-header button {
    width: 60px;
    height: 60px;
}

.adBankPopup .AddCard-Popup-Banner {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.adBankPopup .Addcard-Popup-Input {
    margin-bottom: 20px;
}

.cstmActSlct .MuiInputBase-input {
    margin-top: 16px;
}

.bankDetailsContainer {
    width: 100%;
}

.bankDetailsContainer .parentCard {
    margin-right: 0;
    width: 100%;
}

.bankDtlCard {
    position: relative;
}

.bankDtlCard .cardsData {
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(218, 218, 218);
    padding-bottom: 15px;
}

.bankDtlCard .vrfyStatus {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bankDtlCard .vrfyStatus button {
    border: 1px solid black;
    width: 130px;
}

@media (max-width: 990px) {
    .checkoutContainer {
        flex-wrap: wrap;
    }

    .cardHolder,
    .paymentContainer,
    .paymentDetails .paymentTotal,
    .paymentDetails .paymentCard {
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 450px) {
    .paymentContainer {
        margin-left: 0 !important;
    }

    .checkoutPdt .cardHolder .checkoutCardCnt {
        flex-direction: column;
    }

    .cardHolder img {
        width: 100%;
        height: 198px;
        object-fit: contain;
    }

    .paymentDetails .paymentTotal {
        padding: 15px 15px;
    }

    .paymentDetails .totalTitle span {
        font-size: 18px;
    }

    .paymentDetails p,
    .paymentDetails .totalTitle {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .cardHolder .checkoutPdtTitle,
    .cardHolder .priceTitle {
        font-size: 13px;
    }

    .cardHolder .checkoutPdtName,
    .cardHolder .checkoutPdtPrice {
        font-size: 15px;
    }
}
