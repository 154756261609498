.filter .MuiTypography-root {
    padding-left: 25px;
    flex-basis: unset;
    text-align: left;
    font-size: 14px;
    max-width: 245px;
}
.filter .MuiTypography-root.makeStyles-heading-10 {
    font-size: 16px;
    font-family: var(--fontFamily1) !important;
    font-weight: 600;
    padding-left: 25px;
    flex-basis: unset;
    text-align: left;
}
.filter .MuiPaper-elevation1 {
    box-shadow: none;
    border: 1px solid #eeeeee;
}
.filter .MuiAccordion-rounded:first-child {
    border-radius: 0px;
}
.filter:last-child .MuiAccordion-rounded {
    border-radius: 0px 0px 6px 6px;
}
.filter .MuiTypography-root .MuiFormControlLabel-label {
    padding-left: 0px;
}

.filter .MuiAccordionDetails-root {
    flex-wrap: wrap;
}
