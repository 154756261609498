.favoriteCheck {
    width: 45px;
    height: 45px;
    position: relative;
    background: rgba(0, 0, 0, 0.1568627450980392);
    border-radius: 100px;
}

.favoriteCheck input {
    display: none;
}

.favoriteCheck label {
    color: #fff;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    margin: 0;
    margin-top: 5px;
}

.favoriteCheck label:hover {
    color: grey;
}

.favoriteCheck label::selection {
    color: none;
    background: transparent;
}

.favoriteCheck label::moz-selection {
    color: none;
    background: transparent;
}

.favoriteCheck input:checked + label {
    color: var(--primColor);
}
