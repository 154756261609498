.footNavigator .MuiBottomNavigationAction-root.Mui-selected {
    color: var(--primColor);
}
.footNavigator {
    display: flex;
    z-index: 999;
    position: fixed;
    bottom: 0;
    left: 0;
    justify-content: space-around;
    align-items: center;
    right: 0;
    box-shadow: 0px 0px 6px 2px #00000026;
    background: white;
}

/* responsive css */
@media (max-width: 360px) {
    .footNavigator .MuiBottomNavigationAction-root {
        min-width: 70px;
    }
}
