.pictureSlider .slick-prev {
    left: 5px;
    top: -20px;
    right: 40px;
    left: unset;
}

.pictureSlider .slick-next {
    right: 12px;
    top: -20px;
}

.pictureSlider .slick-next:before,
.pictureSlider .slick-prev:before {
    color: var(--secColor);
}
