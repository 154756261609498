.chatContainer {
    width: 590px;
    height: 340px;
    background-color: #f0eefb;
    border-radius: 10px;
}
.chatContent {
    overflow: auto;
    height: 83.5%;
    padding: 21px;
}
.adminMsgContainer {
    width: max-content;
    padding-bottom: 15px;
}
.adminAccount {
    padding-bottom: 5px;
}
.adminAccountName {
    padding-left: 5px;
    margin: 0px;
}
.adminMsgCnt {
    background-color: white;
    border-radius: 0px 55px 55px 55px;
    padding: 12px 44px 12px 25px;
    width: max-content;
    margin: 0px;
    max-width: 300px;
    word-wrap: break-word;
}
.adminMsg {
    font-family: var(--fontFamily1);
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin: 0px;
    text-align: left;
}
.adminMsgTimeDate {
    font-size: 12px;
    font-family: var(--fontFamily1);
    font-weight: 400;
    padding-top: 5px;
    margin: 0px;
    text-align: right;
}

.publicMsgContainer {
    width: max-content;
    margin-left: auto;
}
.publicAccount {
    padding-bottom: 5px;
}
.publicAccountName {
    padding-left: 5px;
    margin: 0px;
}
.publicMsgCnt {
    background-color: var(--secColor);
    border-radius: 25px 25px 0px 25px;
    padding: 12px 44px 12px 25px;
    width: max-content;
    margin-left: auto;
    max-width: 300px;
    word-wrap: break-word;
}
.publicMsg {
    font-family: var(--fontFamily1);
    font-size: 14px;
    font-weight: 400;
    color: white;
    margin: 0px;
}
.publicMsgTimeDate {
    font-size: 12px;
    font-family: var(--fontFamily1);
    font-weight: 400;
    padding-top: 5px;
    margin: 0px;
    text-align: right;
}
.chatContainer .MuiOutlinedInput-root {
    position: relative;
    border-radius: 0px 0px 0px 10px;
    width: 462px;
    background-color: white;
}
.chatContainer .primButton {
    padding-top: 0px;
}
.chatContainer .primButton.large button {
    height: 56px;
    width: 127px;
    border-radius: 0px 0px 6px 0px;
}
.chatContainer .primButton span.MuiButton-label {
    text-transform: none;
}

/* responsive css */

@media (max-width: 475px) {
    .publicMsgCnt,
    .adminMsgCnt {
        padding: 6px 12px;
        border-radius: 15px 15px 0px 15px;
    }
    .adminMsgCnt {
        border-radius: 0px 15px 15px 15px;
    }
}
