.pastAuctionContainer .pastGridView {
    padding: 30px 25px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 30px;
}

@media (max-width: 600px) {
    .pastAuctionContainer .pastGridView .gridViewCnt {
        max-width: unset;
    }
}
