.category {
    padding: 40px 168px;
}
.catName {
    font-size: 16px;
    font-family: var(--fontFamily1);
    font-weight: 600;
    color: var(--secColor);
    padding-top: 20px;
    margin: 0px;
}
.cat-home {
    padding-left: 20px;
}
.cat-home img {
    height: 115px;
    width: 115px;
}

.cat-home:hover {
    cursor: pointer;
}

@media only screen and (min-device-width: 67em) /* 1072px */ {
    /* STYLES GO HERE */
    /* .category {
        padding: 40px 60px;
    } */
}
@media only screen and (max-device-width: 67em) /* 1072px */ {
    /* STYLES GO HERE */
    .category {
        padding: 40px 60px;
    }
}
@media (max-width: 991px) {
    .category {
        padding: 40px 60px;
    }
}
