.Dashboard .custom-Components .Buynow-Tabs {
    display: flex;
    margin-left: 25px;
}

.Dashboard .custom-Components .Buynow-Tabs a:nth-of-type(4) {
    color: red;
    text-transform: none;
    border-bottom: 2px solid red;
}

.Dashboard .custom-Components .Buynow-Tabs {
    margin-bottom: 30px;
}

.Dashboard .custom-Components .Buynow-Tabs a {
    margin-right: 50px;
    font-weight: 600;
    color: #a8a7a7;
}

.Buynow--Table .MuiButtonBase-root {
    height: 26px;
    padding-top: 5px;
    width: 110px;
}

.Dashboard .buynow-pgn {
    width: max-content;
    margin: 20px auto;
}
